/* Vendors */
import React from 'react';
import styled, { css } from 'styled-components';

/* Components */
import { Background } from '../Background/Background';
import { CustomerLogo } from '../CustomerLogo/CustomerLogo';

/* Utils */
import { withMedia } from '../../utils/withMedia';

/* Types */

/* Styles */
const Items = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  @media (${withMedia('--large-only')}) {
    justify-content: space-around;
  }

  @media (${withMedia('--medium')}) {
    justify-content: space-around;
  }

  @media (${withMedia('--small')}) {
    justify-content: space-around;
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 86px;

  ${({ columns }) => css`
    width: calc(100% / ${columns});
  `}

  @media (${withMedia('--large-only')}) {
    width: calc(100% / 3 - 40px);
  }

  @media (${withMedia('--medium')}) {
    width: calc(100% / 3 - 40px);
  }

  @media (${withMedia('--small')}) {
    width: calc(50% - 40px);
  }
`;

/* Component */
const Component = ({ className, items, columns }) => (
  <Items className={className}>
    {items.map((name) => (
      <Item columns={columns} key={name}>
        <CustomerLogo key={name} name={name} />
      </Item>
    ))}
  </Items>
);

export const Customers = ({ className, visual, items, columns }) =>
  visual ? (
    <Background visual={visual} className={className}>
      <Component columns={columns} items={items} />
    </Background>
  ) : (
    <Component columns={columns} className={className} items={items} />
  );

/* default props */
Customers.defaultProps = {};
