/* Vendors */
import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { FooterContainer } from '@uc-common/footer/styles/Footer.styles';

/* Components */
import { SiteLayout } from '~/src/components/Layout/SiteLayout';
import { Hero } from '~/src/components/Hero/Hero';
import { Customers } from '~/src/components/Customers/Customers';
import { Section } from '~/src/mdx/components/Section/Section';
import { StoryCards } from '~/src/mdx/components/StoryCards/StoryCards';

/* Types */

/* Utils */
import { mapStories } from '../helpers/story-selectors';
import '../helpers/fragments';

/* Styles */

const StyledHero = styled(Hero)`
  h1 {
    font-weight: normal;
    font-size: 68px;
    line-height: 68px;

    @media (max-width: 743px) {
      font-size: 36px;
      line-height: 40px;
    }
  }
`;

const StyledSiteLayout = styled(SiteLayout)`
  ${FooterContainer} {
    border-top: none;
  }
`;

/* Content */

/* eslint-disable max-len */
export const frontmatter = {
  title: 'Case studies',
  description:
    'How Uploadcare helps the world’s fastest growing businesses reliably scale their file-handling infrastructure.',
};
/* eslint-enable max-len */

const customers = ['stackshare', 'prezly', 'mozilla', 'loreal', 'webflow'];

/* Components */

/* Page */
const CaseStudyIndex = ({ location, data: { allMdx } }) => {
  const { pathname } = location;

  const customersOrder = [
    'cube',
    'shogun',
    'printhouse',
    'aryeo',
    'upscribe',
    'framefox',
    'cindicator',
    'chatra',
    'mightycause',
    'polished',
    'zephyr',
    'moodzer',
    'supervisionassist',
    'teopiki',
    'snuggpro',
  ];

  const allStories = mapStories(allMdx).sort((a, b) => {
    const indexA = customersOrder.indexOf(a.name);
    const indexB = customersOrder.indexOf(b.name);

    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  return (
    <StyledSiteLayout meta={frontmatter} pathName={pathname}>
      <StyledHero
        verticalBase="60"
        horizontalBase="120"
        title="The world’s fastest growing businesses choose Uploadcare"
      >
        <Customers columns={5} items={customers} />
      </StyledHero>
      <Section maxWidth={980} visual="ecru-white" verticalBase="60" horizontalBase="120">
        <StoryCards stories={allStories} />
      </Section>
    </StyledSiteLayout>
  );
};

export default CaseStudyIndex;

export const query = graphql`
  query ($sourceName: String!) {
    allMdx(
      filter: { exports: { disabled: { ne: true } }, fields: { sourceName: { eq: $sourceName } } }
    ) {
      edges {
        node {
          ...StoryBase
          ...StoryExports
        }
      }
    }
  }
`;
